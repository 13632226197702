import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { TimeSheetLineModel } from 'timesheet-details/domain/timesheet-lines/TimeSheetLine';
import { TimelineType } from './TimelineType';


export const mapTimeLineHeader = (x) => ({
  id: x.id || '',
  team: x.team,
  technicianId: x.technicianId,
  date: x.date,
  shift: x.shift,
  mealAllowance: x.mealAllowance,
  phOnCall: x.phOnCall,
  onCall: x.onCall,
  rejectionReason: x.rejectionReason || '',
  timelineType: x.timelineType || TimelineType.Invalid,
  breakTime: x.breakTime,
  timesheetDisabled: x.timesheetDisabled,
});

const mapServiceCallDetails = (x) => {
   if (!x) {
     return null;
   }
   return {
    unitMake: x.unitMake || '',
    unitModel: x.unitModel || '',
    companyName: x.companyName || '',
    customerAddress: x.customerAddress || '',
    equipmentId: x.equipmentId || '',
    divisions: x.divisions || '',
    description: x.description || '',
    notes: x.notes || '',
  };
};

const mapTimeSheetHours = (x) => ({
  totalPay: x.totalPay,
  totalGP: x.totalGP,
  allocation: x.allocation,
  normalPay: x.normalPay,
  overtime: x.overtime,
  doubleTime: x.doubleTime,
  overtimeDoubleTime: x.overtimeDoubleTime,
  normalGP: x.normalGP,
  overtimeGP: x.overtimeGP,
  doubleTimeGP: x.doubleTimeGP,
  overtimeDoubleTimeGP: x.overtimeDoubleTimeGP
});

export const mapTimeLine = (x): TimeSheetLineModel => ({
  id: x.id,
  status: x.status,
  type: x.type,
  serviceCallNumber: x.serviceCallNumber || '',
  lostTimeReason: x.lostTimeReason || '',
  startTime: x.startTime,
  endTime: x.endTime,
  callOut: x.callOut,
  serviceCallId: x.serviceCallId || '',
  hours: mapTimeSheetHours(x.hours),
  serviceCallDetails: mapServiceCallDetails(x.serviceCallDetails),
  notes: x.notes
});

export const mapGpAdjustment = (x) => ({
  id: x.id,
  status: x.status,
  type: x.type,
  serviceCallId: x.serviceCallId || '',
  serviceCallNumber: x.serviceCallDetails?.callNumber || '',
  reason: x.reason || '',
  notes: x.notes,
  hours: mapTimeSheetHours(x.hours),
  serviceCallDetails: mapServiceCallDetails(x.serviceCallDetails),
  tempStatus: TimeSheetStatus.Final
});

export const mapPayrollAdjustment = (x) => ({
  id: x.id,
  status: x.status,
  type: x.type,
  leaveType: x.leaveType,
  amount: x.amount || 0,
  reason: x.reason || '',
  startTime: x.startTime,
  endTime: x.endTime,
  callOut: x.callOut,
  negative: x.negative,
  hours: mapTimeSheetHours(x.hours),
});

export const mapAllocation = (x) => ({
  id: x.id,
  timelineId: x.timeLineId || '',
  serviceCallId: x.serviceCallId || '',
  serviceCallNumber: x.serviceCallDetails?.callNumber || '',
  serviceCallDetails: mapServiceCallDetails(x.serviceCallDetails),
  status: x.status,
  hours: x.hours || 0,
  tempStatus: TimeSheetStatus.Final
});

export const mapToTimeSheetDetailsDto = (x) => {
  return {
    header: x.header,
    timeLines: x.timelines.timelines,
    gpAdjustments: x.gpAdjustments.adjustments,
    payrollAdjustments: x.payrollAdjustments.adjustments,
    allocations: x.allocations,
    maximumCallAdjustmentAllocations: x.maximumCallAdjustmentAllocations,
    timeLineType: x.timeLineType,
    breakTime: x.breakTime,
    timesheetDisabled: x.timesheetDisabled,
  };
};
