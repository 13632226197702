import { observer } from 'mobx-react';
import React, { useState} from 'react';
import {
    Grid, IconButton, Typography, makeStyles, Theme, createStyles,
    ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { TimesheetLinesDetails } from './TimesheetLinesDetails';
import { Totals } from '../Total';
import { TimeSheetLinesModel } from '../../domain/timesheet-lines/TimeSheetLines';
import { TimeSheetLineModel } from 'timesheet-details-v2/domain/timesheet-lines/TimeSheetLine';
import { ErrorDetailsIcon } from 'components/ErrorDetailsIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimelineType } from 'timesheet-details-v2/stores/TimelineType';

interface Props {
    disabled?: boolean;
    canAddNonLeave: boolean;
    lostTimeTypes: string[];
    recoverableLostTimeTypes: string[];
    shift: number;
    date: Date;
    timelinesModel: TimeSheetLinesModel;
    getAllocTime: (timeLine: TimeSheetLineModel) => number;
    onDelete: (timeLine: TimeSheetLineModel) => void;
    timeLineType: TimelineType,
    timesheetDisabled: boolean,
    breakTime: number
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: '0 !important'
        },
        summary: {
            '& div:first-child': {
                margin: '0 !important'
            },
            'minHeight': 'auto !important'
        },
        details: {
            padding: '0 !important'
        },
        totals: {
            paddingLeft: 0,
            paddingRight: 0,
            '& div:first-child': {
                margin: '0 !important',
            }
        },
        totalsContainer: {
            paddingLeft: 24,
            paddingRight: 24
        }
    }));

export const TimesheetLines: React.FC<Props> = observer((props) => {
    const flags = useFlags();
    const [isOpen, setIsOpen] = useState(false);
    const handleExpandCollapseClick = () => {
        setIsOpen(!isOpen);
    };

    const { disabled, canAddNonLeave, timelinesModel, getAllocTime, onDelete, lostTimeTypes, shift, date, recoverableLostTimeTypes, timeLineType, timesheetDisabled, breakTime} = props;

    const classes = useStyles();

    const { payrollTotalsValidation, gPTotalsValidation, serviceCallsValidation, lostTimeReasonValidation, breakTimeValidation, fdoWorkedValidation, fdoValidation, breakTimeLinesValidation } = timelinesModel.validateTotals(shift, breakTime);
    const errors = [payrollTotalsValidation, gPTotalsValidation, serviceCallsValidation, lostTimeReasonValidation, breakTimeValidation, fdoWorkedValidation, fdoValidation, breakTimeLinesValidation].filter((e) => !!e).join('; ');
    
    const onAddNewTimeline = () => {
        return timelinesModel.addTimeline(date, lostTimeTypes, recoverableLostTimeTypes, canAddNonLeave, flags.timesheetCreationSecurity, timeLineType);
    };

    return (
        <ExpansionPanel square expanded={isOpen} className={classes.root}>
            <ExpansionPanelSummary className={classes.summary}>
                <Grid container>
                    <Grid item xs={6}>
                        <Grid container alignItems="center">
                            <Grid item xs={4}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {timelinesModel.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={handleExpandCollapseClick}
                                    aria-expanded={isOpen}
                                >
                                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Grid>
                            <Grid item xs={1}>
                                <ErrorDetailsIcon errors={errors}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!isOpen &&
                        <Grid item xs={6}>
                            <Totals hours={timelinesModel.totalHours} />
                        </Grid>
                    }
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <Grid container direction="column">
                    <TimesheetLinesDetails
                        date={date}
                        disabled={disabled}
                        canAddNonLeave={canAddNonLeave}
                        lostTimeTypes={lostTimeTypes}
                        timelines={timelinesModel.timelines}
                        canAdd={timelinesModel.canAddTimeLine}
                        validate={timelinesModel.validate}
                        getAllocTime={getAllocTime}
                        onAdd={onAddNewTimeline}
                        onDelete={onDelete}
                        timeLineType={timeLineType}
                        timesheetDisabled={timesheetDisabled}
                    />
                    {isOpen &&
                        <Grid container direction="row" justifyContent="flex-end" className={classes.totalsContainer}>
                            <Grid item xs={6} className={classes.totals}>
                                <Totals hours={timelinesModel.totalHours} />
                            </Grid>
                        </Grid>
                        }
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
});