export enum TimeSheetLineType {
  Blank = '',
  Travel = 'Travel',
  ServiceCall = 'ServiceCall',
  ServiceCallLostTime = 'ServiceCallLostTime',
  ServiceCallBreakTime = 'ServiceCallBreakTime',
  LostTime = 'LostTime',
  BreakTime = 'BreakTime',
  ExtendedLWOP = 'ExtendedLWOP',
  ParentalLeave = 'ParentalLeave',
  JuryDuty = 'JuryDuty',
  ArmyReserves = 'ArmyReserves',
  CompassionateLeave = 'CompassionateLeave',
  AnnualLeave = 'AnnualLeave',
  SickLeave = 'SickLeave',
  LongServiceLeave = 'LongServiceLeave',
  CarersLeave = 'CarersLeave',
  WorkersComp = 'WorkersComp',
  LeaveWithoutPay = 'LeaveWithoutPay',
  PublicHoliday = 'PublicHoliday',
  Training = 'Training',
  FDO = 'FDO',
  MDO = 'MDO',
  FDOWorked = 'FDOWorked',
  AnniversaryLeave = 'AnniversaryLeave',
  Administration = 'Administration'
}
