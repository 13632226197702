import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import React from 'react';
import SortableTableHead, { HeadCell } from 'components/sortable-table/SortableTableHead';
import { SortOrder, sort } from '@utils/sort';
import { observer } from 'mobx-react-lite';
import { ITechnicianTeam } from 'pay-period-submission/domain';
import TechnicianTeamRow from './TechnicianTeamsRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      width: '100%',
      flex: 1
    }
  }));

interface Props {
  teams: ITechnicianTeam[];
  integrate: (technicianTeam: string) => any
}

const headCells: Array<HeadCell<ITechnicianTeam>> = [
  { id: 'teamName', label: 'Technician Name' },
  { id: 'numberOfUsers', label: 'Number of Technicians' },
  { id: 'integratedUpTo', label: 'Integrated Up To Date' },
  { id: 'actions', label: '' },
  { id: 'errors', label: '' }
];

export const TechnicianTeamsTable: React.FC<Props> = observer((props) => {
  const classes = useStyles();
  const { teams, integrate } = props;

  const [order, setOrder] = React.useState();
  const [orderBy, setOrderBy] = React.useState();

  const handleRequestSort = (newSortOrder: SortOrder, newOrderBy: keyof ITechnicianTeam) => {
    setOrder(newSortOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <Paper className={classes.root}>
      <Table stickyHeader={true} size="small">
        <colgroup>
            <col width="20%" />
            <col width="15%" />
            <col width="20%" />
            <col width="15%" />
            <col width="30%" />
        </colgroup>
        <SortableTableHead<ITechnicianTeam>
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
        />
        <TableBody>
          {sort<ITechnicianTeam>(teams, order, orderBy).map((team) => (
            <TechnicianTeamRow
              key={team.teamName}
              team={team}
              integrate={integrate}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
});
