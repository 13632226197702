import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { PayEnquiryGroup, IPayEnquiryGroup } from '../domain/PayEnquiryGroup';
import { flatMap, isEmpty } from 'lodash';
import { ApiResponse } from 'api';
import { IStoresEnv } from '@core';
import { getPayEnquiries } from 'pay-enquiry/endpoints/getPayEnquiries';
import { exportPayroll } from 'pay-enquiry/endpoints/exportPayroll';
import { formatDate_ddmmyyyy } from '@utils';

export const PayEnquiryStore = types
  .model({
    fromDate: types.optional(types.Date, new Date().setMonth(new Date().getMonth() - 1)),
    toDate: types.optional(types.Date, new Date()),
    payEnquiryGroups: types.optional(types.array(PayEnquiryGroup), []),
    teams: types.optional(types.array(types.string), []),
    selectedTeams: types.optional(types.array(types.string), []),
    isLoading: types.optional(types.boolean, false),
    cutOverDate: types.optional(types.Date, new Date()),
    errorMessage: types.optional(types.string, '')
  })

  .actions(self => {
    const { api, auth } = getEnv<IStoresEnv>(self);

    return ({
      fetchEnquiries: flow(function* (cutOverDate: Date) {
        self.isLoading = true;
        self.cutOverDate = new Date(cutOverDate)
        
        if(new Date(self.fromDate) < new Date(cutOverDate) && new Date(self.toDate) >= new Date(cutOverDate)){
          self.errorMessage = `Please select Start Date and End Date that are either both before the Payroll Metrics System cutoff date, ${formatDate_ddmmyyyy((cutOverDate))}, or both are on or after the cutoff date.`
          self.payEnquiryGroups.clear()
        }
        else{
          self.errorMessage = ''

          const response: ApiResponse<IPayEnquiryGroup[]> = yield getPayEnquiries(api, {
            payload: {

              fromDate: self.fromDate,
              toDate: self.toDate
            },
            errorHandler: 'Failed to fetch pay enquiries',
          });

          applySnapshot(self.payEnquiryGroups, response.data);
        }

        self.isLoading = false;
      }),
      fetchData: flow(function* fetchData() {
        let teams;
        if (auth.user?.isPayrollAdmin) {
          teams = yield api.get(`/api/teams`);

        } else {
          const data = auth.user?.assignedTeams.filter(t => t.isPayrollEnquiry).map(t => t.teamName);
          teams = { data };
        }
        applySnapshot(self.teams, teams.data);
        applySnapshot(self.selectedTeams, teams.data);
      }),
      exportPayroll: flow(function* () {
        let ids = flatMap(self.payEnquiryGroups, item => item.technicianGroups.filter(x => x.selected).map(v => v.technicianId));
        if(ids.length == 0)
        {
          ids = flatMap(self.payEnquiryGroups, item => item.technicianGroups.map(v => v.technicianId))
        }
        self.isLoading = true;

        const response: ApiResponse<BlobPart> =
          yield exportPayroll(
            api,
            {
              payload: {
                ids,
                fromDate: new Date(self.fromDate.getFullYear(),self.fromDate.getMonth(), self.fromDate.getDate(),-(self.fromDate.getTimezoneOffset() /60), -(self.fromDate.getTimezoneOffset() % 60)),
                toDate: new Date(self.toDate.getFullYear(),self.toDate.getMonth(), self.toDate.getDate(),-(self.toDate.getTimezoneOffset() /60), -(self.toDate.getTimezoneOffset() % 60))
              },
              errorHandler: 'Failed to export payroll enquiries.',
            }
          );

        if (response.success) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = response.fileName;
          anchor.target = '_blank';
          document.body.appendChild(anchor);
          anchor.click();
          anchor.remove();
        }

        self.isLoading = false;

        return response.success;
      }),
    });
  })
  .actions(self => {
    return ({
      selectDateRange(from: Date, to: Date) {
        self.fromDate = from;
        self.toDate = to;

        self.fetchEnquiries(self.cutOverDate);
      },
      selectTeams(teams: string[]) {
        self.selectedTeams.replace(teams);
      },
    });
  })
  .views((self) => ({
    get filteredEnquiryGroups() {
      if (!isEmpty(self.selectedTeams)) {
        return self.payEnquiryGroups.filter((g) => self.selectedTeams.includes(g.team)
        );
      }

      return self.payEnquiryGroups;
    },
    get amountOfRows() {
      var totalAmount = 0;
      self.payEnquiryGroups.forEach(g => totalAmount += g.technicianGroups.length)

      return totalAmount;
    },
    get amountOfSelectedRows() {
      var totalAmount = 0;
      self.payEnquiryGroups.forEach(g => totalAmount += g.amountOfSelectedItems)

      return totalAmount;
    }
  }));

export type IPayEnquiryStore = Instance<typeof PayEnquiryStore>;
