import { observer } from 'mobx-react';
import { Grid, Divider, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { TimesheetLineRow } from './TimesheetLineRow';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import { blueGrey } from '@material-ui/core/colors';
import clsx from 'clsx';
import { TimeSheetLineModel } from 'timesheet-details-v2/domain/timesheet-lines/TimeSheetLine';
import { TimeSheetLineType } from 'timesheet-details-v2/domain/timesheet-lines/TimeSheetLineType';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimelineType } from 'timesheet-details-v2/stores/TimelineType';

interface Props {
    disabled?: boolean;
    canAddNonLeave: boolean;
    lostTimeTypes: string[];
    timelines: TimeSheetLineModel[];
    canAdd: boolean;
    date: Date;
    validate: (timeLine: TimeSheetLineModel) => any;
    getAllocTime: (timeLine: TimeSheetLineModel) => number;
    onAdd: () => void;
    onDelete: (allocation: TimeSheetLineModel) => void;
    timeLineType: TimelineType,
    timesheetDisabled: boolean
}

const TimeSheetLineTypes = new Map<string, string>([
    [TimeSheetLineType.Blank, ''],
    [TimeSheetLineType.Travel, 'Travel'],
    [TimeSheetLineType.ServiceCall, 'Service Call'],
    [TimeSheetLineType.ServiceCallLostTime, 'Service Call Lost Time'],
    [TimeSheetLineType.ServiceCallBreakTime, 'Service Call Break Time'],
    [TimeSheetLineType.LostTime, 'Lost Time'],
    [TimeSheetLineType.BreakTime, 'Break Time'],
    [TimeSheetLineType.ExtendedLWOP, 'Extended LWOP'],
    [TimeSheetLineType.ParentalLeave, 'Parental Leave'],
    [TimeSheetLineType.JuryDuty, 'Jury Duty'],
    [TimeSheetLineType.ArmyReserves, 'Army Reserves'],
    [TimeSheetLineType.CompassionateLeave, 'Compassionate Leave'],
    [TimeSheetLineType.AnnualLeave, 'Annual Leave'],
    [TimeSheetLineType.SickLeave, 'Sick Leave'],
    [TimeSheetLineType.LongServiceLeave, 'Long Service Leave'],
    [TimeSheetLineType.CarersLeave, 'Carer\'s Leave'],
    [TimeSheetLineType.WorkersComp, 'Workers Comp'],
    [TimeSheetLineType.LeaveWithoutPay, 'Leave Without Pay'],
    [TimeSheetLineType.PublicHoliday, 'Public Holiday'],
    [TimeSheetLineType.Training, 'Training'],
    [TimeSheetLineType.FDO, 'FDO'],
    [TimeSheetLineType.MDO, 'MDO'],
    [TimeSheetLineType.FDOWorked, 'FDO Worked'],
    [TimeSheetLineType.AnniversaryLeave, 'Anniversary Leave'],
    [TimeSheetLineType.Administration, 'Administration']
  ]);

  
const NonServiceCallTimeSheetLineTypes = new Map<string, string>([
    [TimeSheetLineType.BreakTime, 'Break Time'],
    [TimeSheetLineType.ExtendedLWOP, 'Extended LWOP'],
    [TimeSheetLineType.ParentalLeave, 'Parental Leave'],
    [TimeSheetLineType.JuryDuty, 'Jury Duty'],
    [TimeSheetLineType.ArmyReserves, 'Army Reserves'],
    [TimeSheetLineType.CompassionateLeave, 'Compassionate Leave'],
    [TimeSheetLineType.AnnualLeave, 'Annual Leave'],
    [TimeSheetLineType.SickLeave, 'Sick Leave'],
    [TimeSheetLineType.LongServiceLeave, 'Long Service Leave'],
    [TimeSheetLineType.CarersLeave, 'Carer\'s Leave'],
    [TimeSheetLineType.WorkersComp, 'Workers Comp'],
    [TimeSheetLineType.LeaveWithoutPay, 'Leave Without Pay'],
    [TimeSheetLineType.PublicHoliday, 'Public Holiday'],
    [TimeSheetLineType.FDO, 'FDO'],
    [TimeSheetLineType.MDO, 'MDO'],
    [TimeSheetLineType.AnniversaryLeave, 'Anniversary Leave']
  ]);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        newRow: {
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        row: {
            padding: theme.spacing(1)
        },
        even: {
            backgroundColor: blueGrey[50]
        }
    }));

export const TimesheetLinesDetails: React.FC<Props> = observer((props) => {
    const flags = useFlags();
    const { disabled, canAddNonLeave, timelines, validate, onAdd, onDelete, getAllocTime, lostTimeTypes, canAdd, date, timeLineType, timesheetDisabled } = props;
    const classes = useStyles();

    return (
        <Grid container={true}>
            <Grid item={true} xs={12}>
                <Divider />
            </Grid>
            { timelines.map((line, index) => {
                let timeSheetLineTypes = TimeSheetLineTypes;

                //disbale timesheet type when user has no permission to the selected type
                const disableType = flags.timesheetCreationSecurity && !canAddNonLeave && !line.newLine && !NonServiceCallTimeSheetLineTypes.has(line.type)

                if(timeLineType == TimelineType.TimeLeave){
                    timeSheetLineTypes.delete(TimeSheetLineType.Administration)
                }
                if(timeLineType == TimelineType.AdminLeave){
                    timeSheetLineTypes.delete(TimeSheetLineType.LostTime)
                    timeSheetLineTypes.delete(TimeSheetLineType.ServiceCall)
                    timeSheetLineTypes.delete(TimeSheetLineType.ServiceCallBreakTime)
                    timeSheetLineTypes.delete(TimeSheetLineType.ServiceCallLostTime)
                    timeSheetLineTypes.delete(TimeSheetLineType.Training)
                    timeSheetLineTypes.delete(TimeSheetLineType.FDOWorked)
                }

                //saved timesheet flag in each line so when the flag changed in the middle of creating, created line won't be affected
                if(flags.timesheetCreationSecurity) {
                    //display only non-leave type if:
                    //user has no permission to create non leave and line is already created and line type is a non-leave type
                    //or when user has no permission to create non leave and line is newly added
                    if(!canAddNonLeave && ((!line.newLine && NonServiceCallTimeSheetLineTypes.has(line.type)) || line.newLine)){
                        timeSheetLineTypes = NonServiceCallTimeSheetLineTypes
                    }
                }

                return (<Grid item={true} xs={12} className={clsx(index % 2 === 1 && classes.even, classes.row)}
                        key={`timeline${index}`}
                    >
                        <TimesheetLineRow
                            date={date}
                            disabled={disabled}
                            timeSheetLineTypes={timeSheetLineTypes}
                            lostTimeTypes={lostTimeTypes}
                            validate={validate}
                            timeline={line}
                            getAllocTime={getAllocTime}
                            onDelete={onDelete}
                            disableType={disableType}
                        />
                    </Grid>)
            })
            }
            <Grid item={true} xs={12} className={classes.newRow}>
                <Button
                    disabled={!canAdd || disabled || timesheetDisabled}
                    variant="outlined"
                    color="primary"
                    startIcon={<ControlPointOutlinedIcon />}
                    onClick={onAdd}
                >
                    ADD NEW LINE
                </Button>
            </Grid>
        </Grid>
    );
});
