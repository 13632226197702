import { Instance, types, applySnapshot, flow, getEnv } from 'mobx-state-tree';
import { formatDate_yyyymmdd, millisecondsToSeconds } from '@utils';
import { IStoresEnv } from '@core';
import { TimeSheetDetails } from '../domain/TimeSheetDetails';
import { mapTimeLineHeader, mapTimeLine, mapToTimeSheetDetailsDto,
   mapGpAdjustment, mapPayrollAdjustment, mapAllocation } from './Mappers';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';

export enum ActionType {
  Save,
  Submit,
  Approve,
  Reject
}

const ThrottleExtendLockDurationSeconds = 60;

export const TimesheetDetailsStoreV2 = types
  .model({
    isLoading: types.optional(types.boolean, false),
    dataLoadingError:  types.optional(types.string, ''),
    isLocked: types.optional(types.boolean, false),
    isSessionExpired: types.optional(types.boolean, false),
    lostTimeTypes: types.array(types.string),
    timeSheetDetails: types.optional(TimeSheetDetails, {}),
    originalTimeSheetDetails: types.optional(TimeSheetDetails, {}),
    lastLockDate: types.optional(types.Date, new Date()),
    validationErrors: types.optional(types.array(types.string), []),
    recoverableLostTimeTypes: types.array(types.string),
    payrollAndGPHoursValidationFlag: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const { api } = getEnv<IStoresEnv>(self);

    const applyData = (data) => {
      if (!data) {
        return;
      }

      data.timeLines.sort((a,b) => {
        return ('' + a.startTime).localeCompare(b.startTime);
      })

      applySnapshot(self.originalTimeSheetDetails.header, mapTimeLineHeader(data.header));
      applySnapshot(self.timeSheetDetails.header, mapTimeLineHeader(data.header));

      applySnapshot(self.originalTimeSheetDetails.timelines,
         { timelines: data.timeLines.map((x) => mapTimeLine(x)) });
      applySnapshot(self.timeSheetDetails.timelines,
            { timelines: data.timeLines.map((x) => mapTimeLine(x)) });

      applySnapshot(self.originalTimeSheetDetails.gpAdjustments,
        { adjustments: data.gpAdjustments.map((x) => mapGpAdjustment(x)) });
      applySnapshot(self.timeSheetDetails.gpAdjustments,
        { adjustments: data.gpAdjustments.map((x) => mapGpAdjustment(x)) });

      applySnapshot(self.originalTimeSheetDetails.payrollAdjustments,
        { adjustments: data.payrollAdjustments.map((x) => mapPayrollAdjustment(x)) });
      applySnapshot(self.timeSheetDetails.payrollAdjustments,
        { adjustments: data.payrollAdjustments.map((x) => mapPayrollAdjustment(x)) });

      applySnapshot(self.originalTimeSheetDetails.allocations,
        data.allocations.map((x) => mapAllocation(x)));
      applySnapshot(self.timeSheetDetails.allocations,
        data.allocations.map((x) => mapAllocation(x)));

      applySnapshot(self.validationErrors, []);
    };

    const resetStore = () => {
      applySnapshot(self, {});
    };

    const extendLock = () => {
      const extendLockInner = flow(function* func() {
        if (!self.timeSheetDetails.header.id) {
          return;
        }

        const currentDate = new Date();
        const dateDiff = currentDate.getTime() - self.lastLockDate.getTime();
        if (millisecondsToSeconds(dateDiff) > ThrottleExtendLockDurationSeconds) {
          self.lastLockDate = currentDate;
          yield api.post(`/api/LockTimesheetHeader/lock?id=${self.timeSheetDetails.header.id}&leaseDurationSeconds=${TIMESHEET_LOCK_LEASE_DURATION_SECONDS}`,
          { payload: {}});
        }
        self.lastLockDate = currentDate;
      });

      extendLockInner();
    };

    return {
      resetStore,
      releaseLock: () => {
        const releaseLock = flow(function* func() {
          if (!self.timeSheetDetails.header.id) {
            return;
          }
          yield api.post(`/api/LockTimesheetHeader/unlock?id=${self.timeSheetDetails.header.id}`,
           { payload: {}});
        });

        releaseLock();
      },
      extendLock,
      fetchData: (technicianId: string, date: Date, payrollAndGPHoursValidationFlag: boolean) => {
        const quarryData = flow(function* fetch() {
          self.isLoading = true;
          self.payrollAndGPHoursValidationFlag = payrollAndGPHoursValidationFlag

          const lostTimeTypesResponse = yield api.get(`/api/TimesheetDetails/lostTimeTypes?showDisabled=false`);
          self.lostTimeTypes = lostTimeTypesResponse.data || [];

          const recoverableLostTimeTypesResponse = yield api.get(`/api/UnallocatedLostTime/lostTimeTypes?showDisabled=false`);
          self.recoverableLostTimeTypes = recoverableLostTimeTypesResponse.data || [];

          const response = yield api.get(`/api/TimesheetDetails?technicianId=${technicianId}&date=${formatDate_yyyymmdd(date)}`);
          if (response.status !== 200) {
            self.dataLoadingError = response.error;
            return;
          }

          applyData(response.data);

          self.timeSheetDetails.timelines.timelines.forEach(function(timeline, index){
            self.timeSheetDetails.timelines.timelines[index].lostTimeTypes = self.lostTimeTypes.slice();
            self.timeSheetDetails.timelines.timelines[index].recoverableLostTimeTypes = self.recoverableLostTimeTypes.slice();
            self.timeSheetDetails.timelines.timelines[index].newLine = false;
          })
          
          const maximumCallAdjustmentsAllocationsResult = yield api.get(`/api/TimesheetDetails/GetMaximumGPdjustmentsAllocationsAllowed?date=${formatDate_yyyymmdd(date)}&team=${self.timeSheetDetails.header.team}`);
          if (response.status !== 200) {
            self.dataLoadingError = response.error;
            return;
          }
          const { data: maximumCallAdjustmentsAllocations } = maximumCallAdjustmentsAllocationsResult;
          self.timeSheetDetails.maximumCallAdjustmentAllocations = maximumCallAdjustmentsAllocations;

          const isLockedResponse =
            yield api.get(`/api/LockTimesheetHeader/islocked?id=${response.data.header.id}`);
          const { data: { isLockedByAnotherUser }} = isLockedResponse;
          self.isLocked = isLockedByAnotherUser;
          if (!isLockedByAnotherUser) {
            yield api.post(`/api/LockTimesheetHeader/lock?id=${response.data.header.id}&leaseDurationSeconds=${TIMESHEET_LOCK_LEASE_DURATION_SECONDS}`,
              { payload: {}});
          }

          self.isLoading = false;
        });

        quarryData();
      },
      onSave: () => {
        const saveData = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/TimesheetDetails/save`,
            { payload: mapToTimeSheetDetailsDto(self.timeSheetDetails) });

          const { errors, timeSheetDetails } = response.data;
          if (errors && errors.length > 0) {
            applySnapshot(self.validationErrors, errors);
          } else {
            applyData(timeSheetDetails);
          }

          self.timeSheetDetails.timelines.timelines.forEach(function(timeline, index){
            self.timeSheetDetails.timelines.timelines[index].lostTimeTypes = self.lostTimeTypes.slice();
            self.timeSheetDetails.timelines.timelines[index].recoverableLostTimeTypes = self.recoverableLostTimeTypes.slice();
          })

          const maximumCallAdjustmentsAllocationsResult = yield api.get(`/api/TimesheetDetails/GetMaximumGPdjustmentsAllocationsAllowed?date=${formatDate_yyyymmdd(new Date(self.timeSheetDetails.header.date))}&team=${self.timeSheetDetails.header.team}`);
          if (response.status !== 200) {
            self.dataLoadingError = response.error;
            return;
          }
          const { data: maximumCallAdjustmentsAllocations } = maximumCallAdjustmentsAllocationsResult;
          self.timeSheetDetails.maximumCallAdjustmentAllocations = maximumCallAdjustmentsAllocations;

          self.isLoading = false;
        });

        saveData();
      },
      onSubmit: () => {
        const submitData = flow(function* fetch() {
          self.isLoading = true;

          const response = yield api.post(`/api/TimesheetDetails/submit`,
            { payload: mapToTimeSheetDetailsDto(self.timeSheetDetails) });

          const { errors, timeSheetDetails } = response.data;
          if (errors && errors.length > 0) {
            applySnapshot(self.validationErrors, errors);
          } else {
            applyData(timeSheetDetails);
          }

          self.isLoading = false;
        });

        submitData();
      },
      onApprove: () => {
        const approveTimesheet = flow(function* fetch() {
          self.isLoading = true;

          const {technicianId, date} = self.timeSheetDetails.header;
          const response = yield api.post(`/api/TimesheetDetails/approve-v2?technicianId=${technicianId}&date=${formatDate_yyyymmdd(new Date(date))}`,
          { payload: { } });

          const { errors, timeSheetDetails } = response.data;
          if (errors && errors.length > 0) {
            applySnapshot(self.validationErrors, errors);
          } else {
            applyData(timeSheetDetails);
          }

          self.isLoading = false;
        });

        approveTimesheet();
      },
      onReject: (reason: string) => {
        const rejectTimesheet = flow(function* fetch() {
          self.isLoading = true;

          const {technicianId, date} = self.timeSheetDetails.header;
          const response = yield api.post(`/api/TimesheetDetails/reject?technicianId=${technicianId}&date=${formatDate_yyyymmdd(new Date(date))}`,
          { payload: reason });

          const { errors, timeSheetDetails } = response.data;
          if (errors && errors.length > 0) {
            applySnapshot(self.validationErrors, errors);
          } else {
            applyData(timeSheetDetails);
          }

          self.isLoading = false;
        });

        rejectTimesheet();
      },
      setSessionExpired: () => (self.isSessionExpired = !!self.timeSheetDetails)
    };
  })
  .views((self) => ({
    get hasAnyChanges() {
      return self.timeSheetDetails.hasChanges(self.originalTimeSheetDetails);
    },
    get canSave() {
      const skipShiftValidation = true;
      return self.timeSheetDetails.hasChanges(self.originalTimeSheetDetails)
              && self.timeSheetDetails.isValid(skipShiftValidation, self.timeSheetDetails.header.breakTime, self.payrollAndGPHoursValidationFlag);
    },
    get canSubmit() {
      const skipShiftValidation = self.timeSheetDetails.hasSubmittedTimesheets();
      return (self.timeSheetDetails.hasChanges(self.originalTimeSheetDetails)
              || self.timeSheetDetails.hasAnyItemsWithStatus(TimeSheetStatus.New) || self.timeSheetDetails.hasAnyItemsWithStatus(TimeSheetStatus.Rejected))
          && self.timeSheetDetails.isValid(skipShiftValidation, self.timeSheetDetails.header.breakTime, self.payrollAndGPHoursValidationFlag);
    },
    get canApprove() {
      return !self.timeSheetDetails.hasChanges(self.originalTimeSheetDetails)
              && self.timeSheetDetails.hasAnyItemsWithStatus(TimeSheetStatus.Submitted);
    },
    get canReject() {
      return !self.timeSheetDetails.hasChanges(self.originalTimeSheetDetails)
              && self.timeSheetDetails.hasAnyItemsWithStatus(TimeSheetStatus.Submitted);
    },
    isValid(action: ActionType) {
      return self.timeSheetDetails.isValid(action === ActionType.Save, self.timeSheetDetails.header.breakTime, self.payrollAndGPHoursValidationFlag);
    },
    get disableAllowances() {
       return self.timeSheetDetails.disableAllowances;
    }
  }));

export type ITimesheetDetailsStoreV2 = Instance<typeof TimesheetDetailsStoreV2>;
